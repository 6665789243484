import profile1 from '../assets/profile1.png'
import profile2 from '../assets/profile2.png'

export const TestimonialsData = [
    {
        image : profile1,
        comment: " The Best Sites for Professional Beauty Product Reviews, If you are looking for the best beauty reviews but don’t know how to find them, you’ve come to the right place! Here is a list of ten sites, which are leaders and independent in their reviews.",
        name: "Rinky yadav"
    },
    {
        image : profile2,
        comment: " This is my fav sunscreen to wear anywhere. My dermatologist recommended this amazing one. It lasts longer and the matte finish is so amazing you don't need make up. It license up the faces unlike other sunscreen products dulls ur face.",
        name: "Monu Sharma"
    },
    {
        image : profile1,
        comment: " This was recommended to me by my dermatologist. Its works great on my fungal acne skin. My skin is very sensitive and this worked great on me. It feels like a primer and does not leave your skin oily. This is my third tube of sunscreen. ❤️",
        name: "Alex"
    },
    {
        image : profile1,
        comment: " The Best Sites for Professional Beauty Product Reviews, If you are looking for the best beauty reviews but don’t know how to find them, you’ve come to the right place! Here is a list of ten sites, which are leaders and independent in their reviews.",
        name: "John"
    },
    {
        image : profile2,
        comment: " This is my fav sunscreen to wear anywhere. My dermatologist recommended this amazing one. It lasts longer and the matte finish is so amazing you don't need make up. It license up the faces unlike other sunscreen products dulls ur face.",
        name: "Vishal Verma"
    },
    {
        image : profile1,
        comment: " This was recommended to me by my dermatologist. Its works great on my fungal acne skin. My skin is very sensitive and this worked great on me. It feels like a primer and does not leave your skin oily. This is my third tube of sunscreen. ❤️",
        name: "Stuti Verma"
    }
]